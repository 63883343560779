var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"d6ca10458f46f44ac7495c56ff425b4503280dfa"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";
import { AxiosError } from "axios";
import { Replay } from "@sentry/replay";

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  environment: process.env.ENVIRONMENT,
  enabled: process.env.ENVIRONMENT !== "localhost",
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [new Replay()],
  beforeSend: (event, hint) => {
    const originalException = hint.originalException as AxiosError;
    const response = originalException?.response;
    if (response && response.status && response.status === 404) {
      return null;
    }
    return event;
  },
});
